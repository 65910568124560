<script setup>
const model = defineModel({
    type: String,
    required: true,
});

const props = defineProps({
    label: {
        type: String,
        default: 'input',
    },
    type: {
        type: String,
        default: 'input',
    },
    autocomplete: {
        type: String,
        default: 'input',
    },
    id: {
        type: String,
        default: 'input',
    },
    errormessages: {
        type: String,
        default: '',
    },
    rules: {
      type: Array,
      default: [],
    },
});

</script>

<template>
  <div>
    <v-text-field
      :label="label"
      :rules="rules"
      :type="type"
      :name="id"
      :error-messages="errormessages"
      v-model="model"
      hide-details="auto"
      ref="input"
      class="mb-2"
      :autocomplete="autocomplete"
    ></v-text-field>
  </div>
</template>
<script>
</script>
